import React from "react";
import { TideItemForBlogPageContentGeneral } from "../../types";

interface ArticleIntroProps {
  data?: TideItemForBlogPageContentGeneral;
}

const ArticleIntro: React.FC<ArticleIntroProps> = ({ data }) => {
  return (
    <div className="article-intro">
      <div className="singlecolumn">
        <div className="singlecolumn__container">
          <div className="singlecolumn__row">
            <div className="singlecolumn__column">
              <div className="heading-seperator">
                <div className="tags">
                  {data?.tags?.map((tag, index) => {
                    return (
                      <div key={index} className="tags__tag">
                        {tag?.name}
                      </div>
                    );
                  })}
                </div>
                <h1 className="article__heading">{data?.title}</h1>
                <span className="article-intro__date">{data?.date}</span>
              </div>
              {data?.intro && <div dangerouslySetInnerHTML={{ __html: data.intro }}></div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleIntro;
