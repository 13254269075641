import { graphql, PageProps } from "gatsby";
import { first, isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import NotificationBar from "../../components/notification-bar";
import {
  TideItemForBlogPage,
  TideItemForBlogPageConnection,
  TideItemForHighlightEventsComponent,
  TideItemForSeizoenWedstrijd,
  TideItemForUspFolder,
  TideItemForWebsite,
  TideItemForWedstrijd,
} from "../../types";
import { getChildItemsWithTypename, parse } from "../../utils/component";
import Seo from "../../components/seo";
import MapBlogPageComponents from "../../utils/mappers/map-blog-page-components";
import GlobalContext from "../../contexts/global-provider";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import { PackageProvider } from "../../contexts/package-provider";

export interface BlogPageTemplateData {
  website: TideItemForWebsite;
  page: TideItemForBlogPage;
  usps: TideItemForUspFolder;
  blogs: TideItemForBlogPageConnection;
}

interface BlogPageTemplateContext {
  language: string;
  breadcrumbs: Breadcrumb[];
  tagIds: string[];
}

const BlogPageTemplate: React.FC<PageProps<BlogPageTemplateData, BlogPageTemplateContext>> = ({
  data,
  pageContext: { language, breadcrumbs, tagIds },
}) => {
  const globalContext = useContext(GlobalContext);
  const { games, notificationBar, setGlobalData } = useContext<ComponentContextType>(ComponentContext);
  const [eventIds, setEventIds] = useState<string[]>([]);
  const [filteredGames, setFilteredGames] = useState<TideItemForWedstrijd[]>([]);

  useEffect(() => {
    (async () => {
      if (globalContext) {
        globalContext.setLanguage(language);
      }
      if (!notificationBar) {
        await setGlobalData();
      }
    })();
  }, []);

  useEffect(() => {
    const highlightEventsComponents = getChildItemsWithTypename(
      "TideItemForHighlightEventsComponent",
      data.page
    ) as TideItemForHighlightEventsComponent[];
    if (!isEmpty(highlightEventsComponents) && games) {
      const tagIds = highlightEventsComponents.filter((he) => he.content?.general?.tagId).map((he) => he.content?.general?.tagId);
      if (tagIds) {
        const gamesWithTagId = games.filter((g) => g.content?.general?.tagsIds?.some((tagId) => tagIds.includes(tagId)));
        setFilteredGames(gamesWithTagId);
        setEventIds(
          gamesWithTagId.flatMap(
            (game) =>
              (getChildItemsWithTypename("TideItemForSeizoenWedstrijd", game) as TideItemForSeizoenWedstrijd[])
                .filter((es) => es.content?.general?.eventStartDate && new Date(es.content.general.eventStartDate) >= new Date())
                .map((es) => es.content?.general?.id) as string[]
          )
        );
      }
    }
  }, [games]);

  return (
    <Layout>
      <Seo
        title={parse(data.page.content?.seo?.seoTitle)}
        description={parse(data.page.content?.seo?.seoDescription)}
        keywords={parse(data.page.content?.seo?.seoKeywords)}
        website={parse(data.website)}
        breadcrumbs={parse(breadcrumbs)}
      />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
        />
      )}
      <Navbar website={parse(data.website)} />
      <PackageProvider eventIds={eventIds}>
        <MapBlogPageComponents data={data} breadcrumbs={breadcrumbs} games={filteredGames} />
      </PackageProvider>
      <Footer name={parse(data.website.name)} logo={parse(data.website.content?.general?.logo)} />
    </Layout>
  );
};

export default BlogPageTemplate;
export const query = graphql`
  query BlogPageQuery($pageId: String, $websiteId: String, $tagIds: [String]) {
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      name
      content {
        general {
          phone
          helptext
          linkSgr
          linkThuiswinkelWaarborg
          linkTelephone
          logo {
            altText
            url
            title
          }
          linkLogo
        }
      }
    }
    page: tideItemForBlogPage(id: { eq: $pageId }) {
      id
      name
      content {
        general {
          title
          hero {
            title
            altText
            url
          }
          thumbnail {
            title
            altText
            url
          }
          path
          date
          intro
          featuredbig
          featuredimagebig {
            title
            altText
            url
          }
          featuredsmall
          featuredimagesmall {
            title
            altText
            url
          }
          tagsIds
          tags {
            name
            ... on TideItemForTag {
              id
              name
            }
          }
          maxrelatedblogs
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
      parentItem {
        ... on TideItemForPage {
          id
          name
          content {
            general {
              path
            }
          }
        }
      }
      childItems {
        ...BlocksFragment
      }
    }
    blogs: allTideItemForBlogPage(
      filter: {
        content: { general: { tags: { elemMatch: { id: { in: $tagIds } } } } }
        id: { ne: $pageId }
        parentItem: { parentItem: { parentItem: { id: { eq: $websiteId } } } }
      }
      sort: { order: DESC, fields: content___general___date }
    ) {
      nodes {
        id
        name
        parentNodeId
        content {
          general {
            title
            thumbnail {
              title
              altText
              url
            }
            path
            intro
            date
            tags {
              id
              name
            }
          }
        }
        parentItem {
          ... on TideItemForPage {
            id
            name
            content {
              general {
                path
              }
            }
          }
        }
      }
    }
    usps: tideItemForUspFolder(parentItem: { id: { eq: $websiteId } }) {
      name
      childItems {
        ... on TideItemForUspItem {
          __typename
          id
          name
          content {
            general {
              fai
              text
              title
            }
          }
        }
      }
    }
  }
`;
