import React, { useContext } from "react";
import Article from "../../components/article";
import { Breadcrumb } from "../../components/breadcrumb";
import BlogCardGrid from "../../components/cardgrid/blogs";
import Hero from "../../components/hero";
import { BlogPageTemplateData } from "../../templates/blog-page-template";
import { parse } from "../component";
import translate from "../../utils/translate";
import translations from "./translations.json";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import { TideItemForWedstrijd } from "../../types";

interface MapBlogPageComponentsProps {
  data: BlogPageTemplateData;
  breadcrumbs: Breadcrumb[];
  games: TideItemForWedstrijd[];
}

const MapBlogPageComponents: React.FC<MapBlogPageComponentsProps> = ({ data, breadcrumbs, games }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  return (
    <>
      <Hero image={parse(data.page.content?.general?.hero)} breadcrumbs={breadcrumbs} extraClass="header--compact" />
      <Article data={parse(data.page)} games={games} />
      <BlogCardGrid
        blogs={data.blogs.nodes}
        title={translate(translations, language, "RELATED_BLOGS")}
        maxCount={data.page.content?.general?.maxrelatedblogs ? data.page.content?.general?.maxrelatedblogs : 4}
        usePagination={false}
      />
    </>
  );
};

export default MapBlogPageComponents;
