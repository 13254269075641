import React, { useState } from "react";
import CardGrid from "..";
import { TideItemForBlogPage, TideItemForPage, TideItemForTag } from "../../../types";
import BlogCard from "../cards/blog-card";
import { parse } from "../../../utils/component";
import { filter, find, findIndex } from "lodash";
import Pagination from "./pagination";

interface BlogCardGridProps {
  blogs?: TideItemForBlogPage[];
  title?: string;
  maxCount?: number;
  usePagination: boolean;
}

const BlogCardGrid: React.FC<BlogCardGridProps> = ({ blogs, title, maxCount, usePagination }) => {
  const [currentPage, setCurrentPage] = useState(1);

  let blogsPerPage = currentPage === 1 ? 10 : 12;

  const highlight = find(blogs, "content.general.featuredbig");
  const filterBlogs = filter(blogs, function (currentObject) {
    return currentObject.id !== highlight?.id;
  });

  const focus = find(filterBlogs, "content.general.featuredsmall");

  const moveElementToFirstPosition = (arr: TideItemForBlogPage[], fromIndex: number, toIndex: number) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  };

  if (usePagination && currentPage === 1) {
    moveElementToFirstPosition(blogs as TideItemForBlogPage[], findIndex(blogs, { id: focus?.id }), 0);
    moveElementToFirstPosition(blogs as TideItemForBlogPage[], findIndex(blogs, { id: highlight?.id }), 0);
  }

  let indexOfLastBlog = currentPage * blogsPerPage;
  let indexOfFirstBlog = indexOfLastBlog - blogsPerPage;

  if (currentPage != 1) {
    indexOfFirstBlog = indexOfFirstBlog - 2;
    indexOfLastBlog = indexOfLastBlog - 2;
  }

  const currentBlogs = blogs?.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    }
  };

  const blogcard = (blog: TideItemForBlogPage, i: number) => {
    if (!blog) {
      return <></>;
    }
    return (
      <BlogCard
        key={i}
        title={parse(blog.content?.general?.title)}
        image={parse(blog.content?.general?.thumbnail)}
        tags={parse(blog.content?.general?.tags as TideItemForTag[])}
        intro={parse(blog.content?.general?.intro)}
        date={parse(blog.content?.general?.date)}
        path={parse((blog.parentItem as TideItemForPage).content?.general?.path + "/" + blog.content?.general?.path)}
        extraClassName={
          usePagination && highlight && blog.id == highlight.id
            ? "blogcard--highlight"
            : usePagination && focus && blog.id == focus.id
            ? "blogcard--focus"
            : ""
        }
      />
    );
  };

  return (
    <div className="blog-cards">
      <CardGrid title={title} extraClassName="card-grid--4">
        <>{currentBlogs && currentBlogs.slice(0, maxCount).map((blog, i) => blogcard(blog, i))}</>
      </CardGrid>
      {usePagination && <Pagination currentPage={currentPage} blogsPerPage={blogsPerPage} totalBlogs={blogs?.length as number} paginate={paginate} />}
    </div>
  );
};

export default BlogCardGrid;
