import React from "react";
import { Maybe, TideItem, TideItemForWedstrijd } from "../../types";
import MapBlocksComponents from "../../utils/mappers/map-blocks-components";

interface ArticleContentProps {
  data?: Maybe<TideItem>[];
  games: TideItemForWedstrijd[];
}

const ArticleContent: React.FC<ArticleContentProps> = ({ data, games }) => {
  return (
    <div className="article-content">
      <MapBlocksComponents blocks={data} games={games} />
    </div>
  );
};

export default ArticleContent;
