import React, { useContext } from "react";
import { Image, TideItemForTag } from "../../../../types";
import { parse } from "../../../../utils/component";
import translate from "../../../../utils/translate";
import translations from "./translations.json";
import Link from "../../../link";
import GlobalContext, { GlobalContextType } from "../../../../contexts/global-provider";
import { addWidthParameter } from "../../../../utils/image-utils";

interface BlogCardProps {
  image?: Image;
  title?: string;
  tags?: TideItemForTag[];
  date?: Date;
  intro?: string;
  extraClassName?: string;
  path?: string;
}

const BlogCard: React.FC<BlogCardProps> = ({ image, title, tags, date, intro, extraClassName, path }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);

  return (
    <div className={`blogcard ${extraClassName ?? ""}`}>
      <Link path={`/${path}`} className="blogcard__anchor" title={title}>
        <div className="blogcard__header">
          {image && <img src={addWidthParameter(parse(image.url), 450)} className="blogcard__header-image" alt={parse(image.altText)} />}
          <div className="blogcard__tags">
            <div className="tags">
              {tags &&
                tags.map((tag, i) => (
                  <div key={i} className="tags__tag">
                    {tag.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="blogcard__body">
          <div className="blogcard__text">
            <h3 className="blogcard__heading">{title}</h3>
            <span className="blogcard__date">{date}</span>
            <div className="blogcard__intro">{intro && <div dangerouslySetInnerHTML={{ __html: intro }}></div>}</div>
            <span className="blogcard__read-more" title={translate(translations, language, "READ_MORE")}>
              {translate(translations, language, "READ_MORE")}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;
