import React, { useContext } from "react";
import { TideItemForBlogPage, TideItemForPage, TideItemForWedstrijd } from "../../types";
import { parse } from "../../utils/component";
import ArticleContent from "../article-content";
import ArticleIntro from "../article-intro";
import Link from "../link";
import translate from "../../utils/translate";
import translations from "./translations.json";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";

interface ArticleProps {
  data?: TideItemForBlogPage;
  games: TideItemForWedstrijd[];
}

const Article: React.FC<ArticleProps> = ({ data, games }) => {
  const parent = data?.parentItem as TideItemForPage;
  const { language } = useContext<GlobalContextType>(GlobalContext);
  return (
    <div className="article">
      <div className="article-return">
        <Link path={`/${parse(parent?.content?.general?.path)}`} title={translate(translations, language, "ALL_BLOGS")} className="cta cta--return">
          {translate(translations, language, "ALL_BLOGS")}
        </Link>
      </div>
      <ArticleIntro data={parse(data?.content?.general)} />
      <ArticleContent data={parse(data?.childItems)} games={games} />
      <div className="article-return">
        <Link path={`/${parse(parent?.content?.general?.path)}`} title={translate(translations, language, "ALL_BLOGS")} className="cta cta--return">
          {translate(translations, language, "ALL_BLOGS")}
        </Link>
      </div>
    </div>
  );
};

export default Article;
