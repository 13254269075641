import React from "react";
import Icon from "../../icon";

interface PaginationProps {
  currentPage: number;
  blogsPerPage: number;
  totalBlogs: number;
  paginate: (number: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, blogsPerPage, totalBlogs, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalBlogs / blogsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pager">
      {currentPage != 1 && (
        <div className="pager__return">
          <button type="button" className="pager__button" onClick={() => paginate(1)}>
            <Icon name="first-page" />
          </button>
          <button type="button" className="pager__button" onClick={() => paginate(currentPage - 1)}>
            <Icon name="previous-page" />
          </button>
        </div>
      )}
      <div className="pager__pages">
        {pageNumbers.map((number) => (
          <a key={number} onClick={() => paginate(number)} className="pager__anchor">
            {number}
          </a>
        ))}
      </div>
      {currentPage != pageNumbers.length && (
        <div className="pager__forward">
          <button type="button" className="pager__button" onClick={() => paginate(currentPage + 1)}>
            <Icon name="next-page" />
          </button>
          <button type="button" className="pager__button" onClick={() => paginate(pageNumbers.length)}>
            <Icon name="last-page" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Pagination;
